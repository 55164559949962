import React from "react"
import { graphql } from "gatsby"

import { Flexible as Template } from "../components/Flexible/Flexible"

export const query = graphql`
  query($id: String) {
    page: sanityFlexiblePage(_id: { eq: $id }) {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      settings {
        dark
        transparent
      }
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Template {...props} {...data} />
