import React from "react"

import { useApp } from "../../hooks/useApp"
import { useSection } from "../../hooks/useSection"
import { useSanity } from "../../hooks/useSanity"

export const withFlexible = Component => ({ name = `Flexible`, page }) => {
  const { setInvertedTheme, setTransparentTheme } = useApp()
  const { heroNormaliser } = useSanity()
  const { transformSection } = useSection()

  const content = {
    ...page,
    heroBanner: heroNormaliser(page?.heroBanner),
    sections: page?.content,
  }
  const hero = transformSection(content.heroBanner)

  if (content?.settings) {
    setInvertedTheme(content.settings.dark)
    setTransparentTheme(content.settings.transparent)
  }

  Component.displayName = name
  return <Component content={content} hero={hero} />
}
